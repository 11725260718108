export const ESTAR_API = 'https://hubapiv1.azurewebsites.net/api';
export const MULTIVERSX_API = 'https://api.multiversx.com';
export const EXPLORER_URL = 'https://explorer.multiversx.com';
export const EQUISTAR_API = 'https://equistar.estar.games:3005';
export const EQUISTAR_URL = 'https://equistar.estar.games';
export const TOKEN_IDENTIFIER = 'ESTAR-461bab';
export const API_TIMEOUT = 6000;
export const walletConnectV2ProjectId = '43ee9eaa6e18417d72355622c2d9f2ad';
export const googleId =
  '845043166725-b80hk2r3dio8nfjc7915o9lpfels3ugn.apps.googleusercontent.com';
export const JWT_SECRET = 'oDoq5q131!#68oppTStH';
